/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";
import { useForm } from "@formspree/react";
import _ from "lodash";

function ContactForm() {
  const [state, handleSubmit] = useForm("xwkaelen");
  const [formState, setFormState] = useState({});

  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup
        className={classnames({
          focused: formState.emailFocused
        })}
      >
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Email address"
            type="email"
            name="email"
            id="email"
            onFocus={e =>
              setFormState(prevState => ({ ...prevState, emailFocused: true }))
            }
            onBlur={e =>
              setFormState(prevState => ({ ...prevState, emailFocused: false }))
            }
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-4">
        <Input
          className="form-control-alternative"
          cols="80"
          name="message"
          id="message"
          placeholder="Type a message..."
          rows="4"
          type="textarea"
        />
      </FormGroup>
      <div>
        <Button
          block
          className="btn-round"
          color="default"
          size="lg"
          type="submit"
          disabled={state.submitting}
        >
          Send Message
        </Button>
      </div>
    </form>
  );
}

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div
                className="shape shape-style-1 shape-bg-image"
                style={{
                  backgroundImage: `url(${require("assets/img/bg/top-bg.jpg")})`
                }}
              ></div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        The Year of Mercy of God
                        <span>2021</span>
                      </h1>
                      <p
                        className="lead text-white font-weight-bold"
                        style={{
                          backdropFilter: "blur(2.4px)",
                          borderRadius: "1rem",
                          padding: "2rem"
                        }}
                      >
                        ...He (God) will show compassion, so great is His
                        unfailing love. <span>Lamentations 3:32</span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-single-copy-04" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            About Us
                          </h6>
                          <p className="description mt-3">
                            Our Church is a family, gathering to glorify the
                            Almighty God, the Father, being taught in the
                            teachings of Jesus Christ and being transformed
                            through the power of the Holy Spirit, guided by the
                            priceless principles of the Holy Bible.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Join with us
                          </h6>
                          <p className="description mt-3">
                            As we see in Isaiah 43:21 “This people have I formed
                            for myself; they shall shew forth my praise”, we are
                            called by the Lord to worship and magnify The Triune
                            God thus to prepare all people to inherit the
                            Kingdom of God when Jesus comes back again.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-world-2" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            What we do
                          </h6>
                          <p className="description mt-3">
                            “Open ye the gates, that the righteous nation which
                            keepeth the truth may enter in” This Bible verse
                            that we see in Isaiah 26:2 is our Goal to prepare
                            our People for His Kingdom by keeping the Truth of
                            the Doctrines of the Word of God.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-collection" />
                    </div>
                    <h3>Read our latest articles</h3>
                    <p>Get weekly updates on Sunday Sermons & Announcements</p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-bold-right" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Real life testimonies</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-bold-right" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Bible Meditations</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-bold-right" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Programs and Event Details</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <Button
                      className="mt-4"
                      color="success"
                      href="https://wa.me/message/HYQZRTSUCJFUO1"
                      target="_blank"
                    >
                      <i className="fa fa-whatsapp mr-2" />
                      Connect on Whatsapp
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/bg/worship.jpeg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        Service Timings
                      </h4>
                      <p className="lead text-italic text-white">
                        Oh, magnify the LORD with me, And let us exalt His name
                        together. (Psalms 34: 3)
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <h3>Sunday Services</h3>
                    <p className="lead">
                      First Service: 6 AM - 7 AM <br /> Second Service: 7.30 AM
                      - 8.30 AM <br /> Third Service: 9 AM - 10 AM <br />
                      Sunday School: 10.30 AM (Youtube Stream)
                    </p>
                    <h3>Daily Meditation</h3>
                    <p className="lead"> 5 AM - 6 AM (except on Sunday)</p>
                    <h3>Other Meetings</h3>
                    <p className="lead">
                      Youth Service: 7 PM - 8 PM (every Friday) <br /> Ladies
                      Fasting Prayer: 10.30 AM - 11.30 AM (every Friday)
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Contact Us!</h2>
                  <p className="lead text-white">
                    We proclaim Him, admonishing every man and teaching every
                    man with all wisdom, so that we may present every man
                    complete in Christ. (Colossians 1:28)
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="row justify-content-center">
                    <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="ni ni-square-pin text-primary" />
                    </div>
                  </div>
                  <h5 className="text-center text-white mt-3">Location</h5>
                  <p className="text-center text-white mt-3">
                    White Street, Chettipalayam Road, <br /> Podanur,
                    Coimbatore, <br />
                    Tamilnadu - 641023.
                    <br />
                    <a
                      href="https://goo.gl/maps/53nTaCNS6m8J9QVe8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Locate in Google Maps
                    </a>
                  </p>
                </Col>
                <Col lg="4">
                  <div className="row justify-content-center">
                    <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="ni ni-email-83 text-primary" />
                    </div>
                  </div>

                  <h5 className="text-center text-white mt-3">Email</h5>
                  <p className="text-center text-white mt-3">
                    info@visionchurchofgod.com <br /> visionchurchofgod@gmil.com
                  </p>
                </Col>
                <Col lg="4">
                  <div className="row justify-content-center">
                    <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="ni ni-mobile-button text-primary" />
                    </div>
                  </div>
                  <h5 className="text-center text-white mt-3">Phone</h5>
                  <p className="text-center text-white mt-3">+91 96290 55872</p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Contact Us</h4>
                      <p className="mt-0">We will sync with you soon!</p>
                      <ContactForm />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
